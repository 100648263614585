import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useRef, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { Button, InputPassword, PasswordRules } from '@shared/components'

const useStyles = tss.withName('PasswordForm').create({
  buttonContainer: { marginBottom: '24px' },
})

export interface PasswordFormData {
  newPassword: string
  confirmNewPassword: string
}

interface PasswordFormProps {
  onSubmit: (data: PasswordFormData) => void
  guidingId?: string
}

export const PasswordForm: FC<PasswordFormProps> = ({
  onSubmit,
  guidingId = 'passwordform',
}) => {
  const inputRef = useRef(null)
  const { t } = useTranslation()
  const { classes } = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required(t('common.validations.enter-a-new-password'))
      .min(8, t('common.validations.must-have-at-least-8-characters'))
      .matches(/[a-zA-Z]/, t('common.validations.must-have-at-least-1-letter'))
      .matches(/\d/, t('common.validations.must-have-at-least-1-number'))
      .matches(
        /(\W|_)/,
        t('common.validations.must-contain-1-special-character')
      ),
    confirmNewPassword: yup
      .string()
      .oneOf(
        [yup.ref('newPassword')],
        t('common.validations.password-doesnt-match')
      ),
  })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const newPassword = useWatch({ name: 'newPassword', control })

  const submit = async (data: PasswordFormData) => {
    setIsLoading(true)
    await onSubmit(data)
    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Controller
        name="newPassword"
        control={control}
        render={({ field }) => (
          <InputPassword
            {...field}
            testId="newpassword-input"
            label={t('login.new-password')}
            placeholder={t('login.new-password-placeholder')}
            ref={inputRef}
            guidingId={`${guidingId}-passwordnew`}
          />
        )}
      />

      <PasswordRules password={newPassword} />

      <Controller
        name="confirmNewPassword"
        control={control}
        render={({ field }) => (
          <InputPassword
            {...field}
            testId="confirmnewpassword-input"
            label={t('login.confirm-new-password')}
            placeholder={t('login.confirm-new-password-placeholder')}
            style={{ marginTop: '24px', marginBottom: '24px' }}
            guidingId={`${guidingId}-passwordconfirm`}
          />
        )}
      />

      <Button
        label={t('login.save-new-password')}
        type="submit"
        disabled={!isValid}
        testId="savenewpassword-button"
        isLoading={isLoading}
        containerClassName={classes.buttonContainer}
        guidingId={`${guidingId}-submit`}
      />
    </form>
  )
}
