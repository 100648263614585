import { forwardRef, useEffect, useState } from 'react'
import { useForm, useWatch, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { navigateToUrl } from 'single-spa'
import { tss } from 'tss-react/mui'

import { Box, Card, Link, Typography } from '@mui/material'

import {
  api,
  loginSchema,
  LoginData,
  NotAuthenticated,
  LoginResponse,
} from '@shared/api'
import { Input, InputPassword, Button } from '@shared/components'
import { useFtpPortalHubCommunication } from '@shared/hooks/useFtpPortalHubCommunication'

import { LoginLayout } from '../../component/LoginLayout'

const useStyles = tss.withName('SignIn').create(({ theme }) => ({
  navigationLink: {
    color: `${theme?.palette['network-blue']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '600 !important',
    lineHeight: '20px !important',
    marginTop: '32px !important',
    marginBottom: '32px !important',
    cursor: 'pointer',
  },
  signInButtonContainer: {
    marginBottom: '85px !important',
  },
}))
interface FormData {
  email: string
  password: string
}

export default forwardRef((_, ref) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setApplicationLoginData } = useFtpPortalHubCommunication()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [resetPassword, setResetPassword] = useState<boolean>(false)

  const [serverErrors, setServerErrors] = useState<{
    email?: string
    password?: string
  }>({})

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const email = useWatch({ name: 'email', control })
  const password = useWatch({ name: 'password', control })

  const enableSignIn = email.length > 0 && password.length > 0

  useEffect(() => {
    const onAPIError = (error: NotAuthenticated) => {
      if (!error.data?.error || typeof error.data?.error === 'undefined') {
        return
      }

      const code = error.data.error.statusCode

      switch (code) {
        case 401:
        case 406:
          if (error.name === 'NotAuthenticated') {
            setServerErrors({
              password:
                error.message === 'invalid password'
                  ? t('login.validations.invalid-password')
                  : error.message,
            })

            return
          }

          setServerErrors({
            password: t('login.validations.either-email-password-incorrect'),
          })
          break
        case 403:
          setServerErrors({
            password: t('login.validations.password-expired'),
          })
          setResetPassword(true)
          break
        case 423:
          setServerErrors({
            password: t('login.validations.account-locked'),
          })
          setResetPassword(true)
          break
        default:
          setServerErrors({
            password: `${t('common.something-went-wrong')}. ${t(
              'common.please-try-again-later'
            )}.`,
          })
      }
    }

    api.on('apiError', onAPIError)

    return () => {
      api.off('apiError', onAPIError)
    }
  }, [])

  useEffect(() => {
    if (email.length === 0 || password.length === 0) {
      setServerErrors({})
    }
  }, [email, password])

  const onSubmit = async (data: LoginData) => {
    setServerErrors({})

    try {
      await loginSchema.validate(data)
    } catch (error) {
      setServerErrors({
        password: t('login.validations.either-email-password-incorrect'),
      })
      return
    }

    setIsLoading(true)

    try {
      const authData = await api.authenticate(data)

      if (authData) {
        const authorization = await api.service('users').authorize()

        const locations = authorization.locations

        const primaryLocationData = locations?.find(
          (location) => location.id === authData.token.primary_location_id
        )
        localStorage.setItem('fortis:location', primaryLocationData.id)

        const baseUrl = `${process.env.URL_BASE_PATH}`

        // Removing session items that were stored in a previous session
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i)
          if (key && key.startsWith('fortis:session')) {
            localStorage.removeItem(key)
            i--
          }
        }

        // module_access: 1 is merchant and 2 is partner
        const partnerLocationTypes = ['agent', 'office']
        if (
          partnerLocationTypes.includes(primaryLocationData?.location_type) &&
          (authorization.module_access as number[]).includes(2)
        ) {
          navigateToUrl(`${baseUrl}/partner`)
        } else if ((authorization.module_access as number[]).includes(1)) {
          navigateToUrl(`${baseUrl}/merchant/dashboard`)
        } else {
          setServerErrors({
            password: t('login.validations.not-possible-redirect'),
          })
        }

        setApplicationLoginData(authData as unknown as LoginResponse)
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <LoginLayout
      title={t('login.sign-in-to-your-account')}
      description={t('login.welcome-back')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              testId="email-input"
              label={t('common.email')}
              placeholder={t('login.enter-email-placeholder')}
              error={!!serverErrors.email}
              helperText={serverErrors.email}
              style={{
                marginBottom: '32px !important',
              }}
              guidingId="login-email"
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <InputPassword
              {...field}
              testId="password-input"
              label={t('common.password')}
              placeholder={t('login.enter-password-placeholder')}
              error={!!serverErrors.password}
              helperText={serverErrors.password}
              guidingId="login-password"
            />
          )}
        />

        <Box
          data-testid="forgotpassword-link"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Link
            className={classes.navigationLink}
            underline="none"
            data-testid="password-link"
            onClick={() => {
              resetPassword
                ? navigate(`/login/enter-code?email=${email}&reason=expired`)
                : navigate('/login/forgot-password')
            }}
            data-guiding-id={
              resetPassword ? 'login-password-reset' : 'login-password-forgot'
            }
          >
            {resetPassword
              ? t('common.password-reset')
              : t('login.forgot-your-password')}
          </Link>
        </Box>

        <Button
          label={t('login.sign-in')}
          type="submit"
          disabled={!enableSignIn}
          testId="signin-button"
          containerClassName={classes.signInButtonContainer}
          isLoading={isLoading}
          guidingId="login-submit"
        />
      </form>
    </LoginLayout>
  )
})
