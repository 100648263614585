import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { navigateToUrl } from 'single-spa'

import { api } from '@shared/api'
import { useNotification } from '@shared/hooks'
import { toArrayFieldErrors } from '@shared/utils'

import { LoginLayout } from '../../component/LoginLayout'
import { PasswordForm } from '../../component/PasswordForm'

export const SetPassword = () => {
  const { t } = useTranslation()
  const { setNotification } = useNotification()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { userVerificationId } = useParams()
  const hash = searchParams.get('hash')

  const handleSubmit = useCallback(
    async ({ newPassword }: { newPassword: string }) => {
      if (!userVerificationId || !hash) return
      try {
        await api
          .service('public/user-verification')
          .verify(userVerificationId, hash, newPassword)

        navigate('/login')
      } catch (error) {
        const errors = toArrayFieldErrors(error)
        setNotification({
          label: errors.length > 0 ? errors[0].message : error.message,
          type: 'error',
        })
      }
    },
    [userVerificationId, hash]
  )

  if (!hash || !userVerificationId) {
    navigateToUrl('/login')
  }

  return (
    <LoginLayout
      title={t('login.create-your-password')}
      description={t('login.create-new-password-text-2')}
    >
      <PasswordForm
        onSubmit={handleSubmit}
        guidingId="login-createpasswordnew"
      />
    </LoginLayout>
  )
}
