import { useEffect, useRef, useState } from 'react'
import { useForm, useWatch, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { AlertColor, Box, Card, Link, Typography } from '@mui/material'

import { api } from '@shared/api/src'
import { Input, Button } from '@shared/components'
import { useNotification } from '@shared/hooks'

import { LoginLayout } from '../../component/LoginLayout'

const useStyles = tss.withName('PasswordResetCode').create(({ theme }) => ({
  subtitle: {
    color: `${theme.palette['neutral-600']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '30.017px !important',
  },
  subtitle2: {
    color: `${theme.palette['neutral-900']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '16px !important',
    fontWeight: '500 !important',
    lineHeight: '30.017px !important',
  },
  navigationLink: {
    color: `${theme.palette['network-blue']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '600 !important',
    lineHeight: '20px !important',
  },
  continueButtonContainer: { marginBottom: '42px !important' },
  resendButtonContainer: { marginBottom: '24px' },
}))

interface FormData {
  code: string
}

export default function PasswordResetCode() {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setNotification } = useNotification()

  const inputRef = useRef(null)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const email = searchParams.get('email')
  const reason = searchParams.get('reason')
  const emailValidationSchema = yup.string().email()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [serverErrors, setServerErrors] = useState<{
    code?: string
  }>({})

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      code: '',
    },
  })

  const code = useWatch({ name: 'code', control })

  const enableContinue = code.length > 0

  const hasErrors =
    serverErrors.code?.length > 0 || serverErrors.code?.length > 0

  useEffect(() => {
    if (!email || !emailValidationSchema.isValidSync(email)) {
      navigate('/login')
    } else {
      inputRef.current.focus()

      if (reason === 'expired') {
        api.service('public/users').create_code(email)
      }
    }
  }, [])

  useEffect(() => {
    setServerErrors({})
  }, [code])

  const onSubmit = async (data: FormData) => {
    // this verification comes first because of tests
    if (data.code.length !== 6) {
      setServerErrors({ code: t('login.validations.code-length') })
      return
    }

    if (!email) return

    setIsLoading(true)

    try {
      const status = await api
        .service('public/users')
        .check_code(email, data.code)

      if (!status.ok) {
        setIsLoading(false)
        setServerErrors({ code: t('login.validations.code-invalid') })
        return
      } else {
        navigate(
          `/login/create-password?email=${email}&code=${data.code}&reason=${
            reason || 'forgot'
          }`
        )
      }
    } catch (error) {
      setServerErrors({
        code: t('login.validations.code-invalid'),
      })
    } finally {
      setIsLoading(false)
    }
  }

  const resendCode = async () => {
    try {
      await api.service('public/users').create_code(email)

      setNotification({
        type: 'success',
        label: t('login.please-check-your-email'),
      })
    } catch (error) {
      setNotification({
        type: 'error',
        label: error.message,
      })
    }
  }

  return (
    <LoginLayout
      title={t('login.enter-password-reset-code-title')}
      description={
        <>
          {`${t('login.enter-password-reset-code-text-1')} `}
          <strong>{t('login.password-reset-code')}</strong>
          {`. ${t('login.enter-password-reset-code-text-2')}.`}
        </>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              testId="code-input"
              label={t('login.password-reset-code')}
              placeholder={t('login.password-reset-code-placeholder')}
              error={hasErrors}
              helperText={serverErrors.code}
              style={{
                marginBottom: '32px !important',
              }}
              ref={inputRef}
              guidingId="login-resetcode"
            />
          )}
        />

        <Button
          label={t('common.continue')}
          type="submit"
          disabled={!enableContinue}
          testId="continue-button"
          containerClassName={classes.continueButtonContainer}
          isLoading={isLoading}
          guidingId="login-resetcode-submit"
        />
      </form>

      <Typography className={classes.subtitle2} sx={{ marginBottom: '8px' }}>
        {t('login.did-not-receive-an-email')}
      </Typography>

      <Typography className={classes.subtitle} sx={{ marginBottom: '24px' }}>
        {`${t('login.please-check-your-spam')} `}
        <strong>855-465-9999</strong>.
      </Typography>

      <Button
        label={t('login.resend-email')}
        type="submit"
        testId="resendemail-button"
        color="secondary"
        containerClassName={classes.resendButtonContainer}
        onClick={resendCode}
        guidingId="login-resetcode-resendemail"
      />

      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '69px',
        }}
      >
        <Link
          className={classes.navigationLink}
          underline="none"
          href=""
          onClick={() => navigate('/login')}
          data-guiding-id="login-resetcode-backtologin"
        >
          {t('login.back-to-sign-in')}
        </Link>
      </Box>
    </LoginLayout>
  )
}
