import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Button } from '@shared/components'

import { LoginLayout } from '../../component/LoginLayout'

const useStyles = tss
  .withName('NewPasswordSuccessfullyCreated')
  .create(({ theme }) => ({
    buttonContainer: {
      marginBottom: '28px !important',
    },
  }))

export default function NewPasswordSuccessfullyCreated() {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const navigate = useNavigate()

  return (
    <LoginLayout
      title={t('login.new-password-created-title')}
      description={t('login.new-password-created-text')}
    >
      <Button
        label={t('login.sign-in')}
        testId="sign-in-button"
        containerClassName={classes.buttonContainer}
        onClick={() => navigate('/login')}
        guidingId="login-passwordcreate-backtologin"
      />
    </LoginLayout>
  )
}
